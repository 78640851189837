import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const Flex = styled.div `
    max-width: 1110px;
    margin: 81px auto 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 81px;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 767px) {
        margin-top: 33px;
        display: none;
    }
`

const HeroTextDiv = styled.div `
    width: 100%;
`;

const HeroDiv = styled(BackgroundImage)`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center bottom;
    background-color: #E5F5FB;
    height: 240px;
    margin-top: 80px;

    @media only screen and (max-width: 767px) {
        height: 190px
    }
`;

const query = graphql`
    query {
        sanityHero(title: { eq: "Contact Us" }) {
            title
           heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
          }
      }
  `

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <HeroDiv fluid={data.sanityHero.background_image.asset.fluid}>
                    <Flex>
                    <HeroTextDiv className="hero">
                        <h1>Request Your Free Trial!</h1>
                    </HeroTextDiv>
                    </Flex>
                </HeroDiv>
            )
        }}
    />
)