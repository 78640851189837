import React from "react"
import Hero from "../../components/hero-demo"
import Form from "../../components/form-demo-ecc";


import "../../assets/index.css"
import Layout from "../../layouts/layout-contactus"

export default () => (
    <Layout title="Enhanced Cycle Counting Trial | Ladd Partners">
        <Hero/>
        <Form/>
    </Layout>
)
