import React from "react"
import styled from "styled-components"
import Bar from "./bar"
import Recaptcha from 'react-recaptcha';

/* eslint-disable jsx-a11y/accessible-emoji */
const ContactUsWrapperDiv = styled.div`
    background-color: #E5F5FB;
    padding-bottom: 100px;

    #g-recaptcha > div {
        float: right;
    }
`;

const ContactWrapperDiv = styled.div `
    display: flex;
    flex-wrap: wrap;
    max-width: 1110px;
    margin: 0 auto;
    padding: 60px 20px;
    box-sizing: border-box;
    width: 100%;

    @media only screen and (max-width: 1140px) {
        width: 100%;
    }
`;

const ContactUs = styled.div`
    width: 50%;
    box-sizing: border-box;
    
    h2 {
        font-size: 28px;
    }
    > div {
        width: 100%;
        max-width: 400px;
    }

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 40px 0px;
    }
`;

const ContactUsForm = styled.div`
    width: 50%;
    box-sizing: border-box;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0 5px 0;
    }

    p {
        margin-bottom: 20px;
    }

   form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        @media only screen and (max-width: 1140px) {
            margin-bottom: 0;

            .g-recaptcha > div {
                width: 100% !important;
            }
        }

        > div {
            width: 100%;
            position: relative;
        }
    }

    .radio {
        display:inline-block;
        margin-right: 40px;
    }
    
    input[type='radio'] { 
        transform: scale(1.4); 
        margin-right: 20px;
    }

    input:not([type=checkbox]):not([type=radio]), textarea {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        border: #919EAB 1px solid;
        margin-bottom: 30px;
        background: #F9FCFF;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        ::placeholder {
            color: #919EAB;
            font-weight: 500;
          }

        :focus, :hover {
            outline: none;
            border-color: #919AA2;
        }
    }

    .has-error input,
    .has-error textarea {
        border-color: #F70545;
    }

    has-error.textarea {
        margin-bottom: 35px;
    }

    .has-error p {
        color: #F70545;
        font-size: 14px;
        position: absolute;
        margin-bottom: 0;
        bottom: 5px;
    }

    .has-error textarea + p {
        @media only screen and (max-width: 400px) {
            bottom: -15px;
        }
    }

    button {
        @media only screen and (min-width: 1140px) {
            max-width: 260px;
        }
    }
`;

class ContactUsPage extends React.Component {
    state = {
        email: '',
        first_name: '',
        last_name: '',
        formErrors: {
            email: '',
            first_name: '',
            last_name: '',
        },
        emailValid: false,
        firstNameValid: false,
        lastNameValid: false,
        formValid: false
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;

        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid.';
            break;
          case 'first_name':
            firstNameValid = value.length > 0;
            fieldValidationErrors.first_name = firstNameValid ? '': ' is required.';
            break;
          case 'last_name':
                lastNameValid = value.length > 0;
                fieldValidationErrors.last_name = lastNameValid ? '': ' is required.';
            break;
        default:
            break;
        }

        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        firstNameValid: firstNameValid,
                        lastNameValid: lastNameValid
                      }, this.validateForm);
      }

      handleSubmit = event => {
        document.querySelectorAll('.validate').forEach((input) => {
            const value = input.value
            const name = input.name
            this.setState({[name]: value},
                () => { this.validateField(name, value) });
        })
    
        if (!this.state.formValid) event.preventDefault();
      }

      validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.firstNameValid && this.state.lastNameValid});
      }

      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
     }

     errorMessage(name, error) {
        return(error.length === 0 ? '' : <p key={name}>{name} {error}</p>);
     }

     callback() {
         document.querySelector('#submit').removeAttribute('disabled');
     }

      render() {
        return (
            <ContactUsWrapperDiv>
            
            <ContactWrapperDiv>
                <ContactUs>
                    <div>
                        <h2>Request your free trial of Enhanced Cycle Counting</h2>
                        <p>Please provide your contact information and we will work with you to arrange a free 30 day trial of our Enhanced Cycle Counting.</p>
                    </div>
                </ContactUs>
                <ContactUsForm>
                    <Bar/>
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" noValidate onSubmit={this.handleSubmit}> 
                        <input type="hidden" name="oid" value="00D36000000pbA2"/>
                        <input type="hidden" name="retURL" value="https://www.laddpartners.com/solutions/enhancedcyclecounting-trial/?submit=success"/>
                        <input type="hidden" name="lead_source" value="WebPage-CycleCount"/>

                        <div className={`form-group ${this.errorClass(this.state.formErrors.first_name)}`}>
                            <input id="first_name" className="validate" maxLength="40" name="first_name" size="20" placeholder="Your First Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.first_name}
                                            ></input>
                            {this.errorMessage('First Name', this.state.formErrors.first_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.last_name)}`}>
                            <input id="last_name" className="validate" maxLength="80" name="last_name" size="20" placeholder="Your Last Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.last_name}
                                            ></input>
                            {this.errorMessage('Last Name', this.state.formErrors.last_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                            <input id="email" className="validate" maxLength="80" name="email" size="20"  placeholder="Your Email" required
                                              onChange={this.handleInputChange}
                                              value={this.state.email}
                                              ></input>
                            {this.errorMessage('Email', this.state.formErrors.email)}
                        </div>
                        <div>
                            <input id="company" maxLength="40" name="company" size="20" placeholder="Your Company"
                                            onBlur={this.handleInputChange}></input>
                        </div>

                        <div className="margin-bottom-20">
                            <div>
                                <p>Are you an existing Ladd Partners customer? </p>
                            </div>
                            <div className="radio">
                                <input id="existing_customer_true" value="true" name="existing_lp_customer" type="radio"  defaultChecked onBlur={this.handleInputChange}/>
                                <label for="existing_customer_true">Yes</label>
                            </div>

                            <div className="radio">
                                <input id="existing_customer_false" value="false" name="existing_lp_customer" type="radio" onBlur={this.handleInputChange}/>
                                <label for="existing_customer_false">No</label>
                            </div>

                        </div>
                        <div>
                        <Recaptcha
                            sitekey="6LcnBfEUAAAAAA0ZZbvkTUH4USvHiNvF9DHHK77f"
                            render="explicit"
                            verifyCallback={this.callback}
                        />
                        </div>
                        <div><button id="submit" type="submit" disabled>Submit Request</button></div>
                    </form>
                </ContactUsForm>
            </ContactWrapperDiv>
        </ContactUsWrapperDiv>
        )
      }
}

export default ContactUsPage